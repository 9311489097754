import React, { Component } from 'react';
import axios from 'axios';
import '../assets/courierlist.css'


class CarrierCard extends Component {
    constructor(props) {
      super(props);
      this.state = {
      };
    }

    handleShopNowClick = async (shipment, country, id) => {
      const { setShowShops } = this.props;
      try {
        const response = await axios.get('https://www.parcellodge.com/api/parcels/retrieve-service-points', {
          params: {
            from_postal_code: localStorage.getItem("collectionpostcodes"),
            to_country: localStorage.getItem("selectedcountry"),
            carrier: shipment.carrier,
          },
        });
        localStorage.setItem("carrierselected", shipment.carrier);
        setShowShops(true, response.data.data);
      } catch (error) {
        console.error("Error fetching service points", error);
      }
    };

    goToBooking = async(shippingId, price, carrier) => {
      // const shippingdata = await this.props.onBookNow()
      // console.log("shippingdata===",shippingdata)
      localStorage.setItem("selectedShippingId", shippingId);
      localStorage.setItem("quoteestimation", price);
      localStorage.setItem("carrierselected", carrier);
     //window.location.href=`/bookparcel?shippingid=${Number(localStorage.getItem("selectedShippingId"))}&self`
     window.location.href = `/bookparcel?shippingid=${shippingId}&self`;
    }
  render() {
    const { carrier, onShopNow, onBookNow  } = this.props;
    return (
      <div className="card">
        <div className="card-header">
          <h2>{carrier.carrier}</h2>
        </div>
        {console.log("check carrier in card---",carrier)}
        {carrier.shipments.map((shipment) => (
  <div key={shipment.id} className="shipment-details">
    <h3>{shipment.name}</h3>
    <ul className="list-group">
  {shipment.countries.map((country) => (
    <li key={country.id} className="list-group-item mb-3">
      <h4 className="mb-3">Price Breakdown</h4>
      {(country.lead_time_hours !== null) && (
  <div className="d-flex justify-content-between mb-2 a">
    <span className="fw-bold">Lead Time:</span>
    <span>{country.lead_time_hours/24} Days</span>
  </div>
)}
      {(country.price_breakdown[0]?.value !== undefined) && (
  <div className="d-flex justify-content-between mb-2 a">
    <span className="fw-bold">{country.price_breakdown[0]?.type}:</span>
    <span>£{country.price_breakdown[0]?.value.toFixed(2)}</span>
  </div>
)}

{/* {(country.price_breakdown[1]?.value !== undefined) && (
  <div className="d-flex justify-content-between mb-2 b">
    <span className="fw-bold">{country.price_breakdown[1]?.type}:</span>
    <span>£{country.price_breakdown[1]?.value.toFixed(2)}</span>
  </div>
)} */}

{(country.price_breakdown[1]?.value !== undefined && 
  localStorage.getItem("insurance") === "true") && (
  <div className="d-flex justify-content-between mb-2 b">
    <span className="fw-bold">{country.price_breakdown[1]?.type}:</span>
    <span>£{country.price_breakdown[1]?.value.toFixed(2)}</span>
  </div>
)}


{(country.price_breakdown[2]?.value !== undefined) && (
  <div className="d-flex justify-content-between mb-2 c">
    <span className="fw-bold">{country.price_breakdown[2]?.type}:</span>
    <span>£{country.price_breakdown[2]?.value.toFixed(2)}</span>
  </div>
)}

{(country.price_breakdown[3]?.value !== undefined) && (
  <div className="d-flex justify-content-between mb-2 d">
    <span className="fw-bold">{country.price_breakdown[3]?.type}:</span>
    <span>£{country.price_breakdown[3]?.value.toFixed(2)}</span>
  </div>
)}

{(country.price_breakdown[4]?.value !== undefined) && (
  <div className="d-flex justify-content-between mb-2 d">
    <span className="fw-bold">{country.price_breakdown[4]?.type}:</span>
    <span>£{country.price_breakdown[4]?.value.toFixed(2)}</span>
  </div>
)}

{(country.price_breakdown.some(item => item?.value !== undefined)) && (
  <div className="d-flex justify-content-between mt-3 mb-4">
    <span className="fw-bold">Total Price:</span>
    <span className="text-primary">£{country.price.toFixed(2)}</span>
  </div>
)}



      {/* <div className="d-flex justify-content-between mt-3 mb-4">
        <span className="fw-bold">Total Price:</span>
        <span className="text-primary">£{country?.price}</span>
      </div> */}
      
     

  <div class="d-flex justify-content-center">
      {/* <button className='btn btn-secondary py-2 px-3' onClick={onShopNow}>
        Show Shops
      </button> */}
      <button
            className="btn btn-secondary py-2 px-3"
            onClick={() => this.handleShopNowClick(shipment, country, shipment.id)}
          >
            Show Shops
      </button>
      {!shipment.name.includes("DPD") || !shipment.name.includes("Dropoff") ? (
        <button
          className="btn btn-secondary py-2 px-3 ml-2"
          onClick={() => this.goToBooking(shipment.id, country.price, shipment.carrier)}
        >
          Book
        </button>
      ) : null}
   
      </div>
    </li>
  ))}
</ul>
  </div>
))}

      </div>
    );
  }
}

export default CarrierCard;
