// App.js
import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate, Redirect   } from 'react-router-dom';

import Home from './Home'; // import your page components
import About from './About';
import Booking from './Booking';
import Contact from './Contact';
import Vehicles from './Vehicles';
import Pay from './Pay';
import Industries from './Industries';
import Services from './Services';
import Service from './Service';
import Industry from './Industry';
import BecomeDriver from './BecomeDriver';
import City from './City';
import Terms from './Terms';
import ThankYou from './ThankYou';
import CookiePolicy from './CookiePolicy';
import UnderConstruction from './UnderConstruction';
import ComingSoon from './ComingSoon';

import ProhibitedItems from './ProhibitedItems';
import ParcelProtection from './ParcelProtection';
import LabelingPackagingCriteria from './LabelingPackagingCriteria';
import CustomsChargesDutiesAndTaxes from './CustomsChargesDutiesAndTaxes';
import UKParcelDelivery from './UKParcelDelivery';
import InternationalDelivery from './InternationalDelivery';
import PalletDelivery from './PalletDelivery';
import EVRI from './EVRI';
import DPD from './DPD';
import UPS from './UPS';
import RoyalMail from './RoyalMail';
import FedEx from './FedEx';
import ParcelForce from './ParcelForce';
import Pallet from './Pallet';
import DHL from './DHL';
import Carriers from './Carriers';
import OrderManagement from './src/OrderManagement';
import Coming from './Coming';
import CartManagement from './src/CartManagement';
import CourierList from './CourierList';
import ParcelBooking from './ParcelBooking';
import TwentyFourHours from './twentyFourHours';
import FortyEightHours from './fortyEightHours';
import MultiDrop from './multiDrop';
import VolumetricWeight from './volumetricWeightCalculator';
import LengthGrith from './lengthGirthCalculator';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      webLoading: true,
      isUnderConstruction: false 
    };
  }

  componentDidMount() {
    
    
  }
  render() {
    const { webLoading } = this.state;
    const { isUnderConstruction } = this.state;

    if (isUnderConstruction) {
      return <UnderConstruction />;
    }
  return (
    <div>
        
          <div>
           <BrowserRouter>
          <Routes>
          <Route path="/multidrop" element={<MultiDrop />} />
          <Route path="/fortyEightHours" element={<FortyEightHours />} />
          <Route path="/twentyFourHours" element={<TwentyFourHours />} />
            <Route path="/home/:category" element={<Home />} />
            <Route path="/home" element={<Home />} />    
            <Route path="/about" element={<About />} />     
            <Route path="*" element={<Navigate to="/home" />} />
            <Route path="/booking" element={<Booking />}/>
            <Route path="/contact" element={<Contact />}/>
            <Route path="/vehicles" element={<Vehicles />}/>
            <Route path="/pay" element={<Pay />}/>
            <Route path="/industries" element={<Industries />}/>
            <Route path="/services" element={<Services />}/>
            <Route path="/service" element={<Service />}/>
            <Route path="/industry" element={<Industry />}/>
            <Route path="/becomedriver" element={<BecomeDriver />}/>
            <Route path="/city/:cityName" element={<City />} />
            <Route path="/terms" element={<Terms />}/>
            <Route path="/thankyou" element={<ThankYou />}/>
            <Route path="/cookiepolicy" element={<CookiePolicy />}/>
            <Route path="/coming" element={<Coming />}/>

            <Route path="/prohibiteditems" element={<ProhibitedItems />} />
              <Route path="/parcelprotection" element={<ParcelProtection />} />
              <Route path="/labelingpackagingcriteria" element={<LabelingPackagingCriteria />} />
              <Route path="/customschargesdutiesandtaxes" element={<CustomsChargesDutiesAndTaxes />} />
              <Route path="/ukparceldelivery" element={<UKParcelDelivery />} />
              <Route path="/internationaldelivery" element={<InternationalDelivery />} />
              <Route path="/palletdelivery" element={<PalletDelivery />} />
              <Route path="/evri" element={<EVRI />} />
              <Route path="/dpd" element={<DPD />} />
              <Route path="/ups" element={<UPS />} />
              <Route path="/royalmail" element={<RoyalMail />} />
              <Route path="/fedex" element={<FedEx />} />
              <Route path="/parcelforce" element={<ParcelForce />} />
              <Route path="/pallet" element={<Pallet />} />
              <Route path="/dhl" element={<DHL />} />
              <Route path="/carriers" element={<Carriers />} />
              <Route path="/orders" element={<OrderManagement />} />
              <Route path="/carts" element={<CartManagement />} />
              <Route path="/couriers" element={<CourierList />} />
              <Route path="/bookparcel" element={<ParcelBooking />} />
              <Route path="/bookparcel" element={<ParcelBooking />} />
              <Route path="/volumetricweight" element={<VolumetricWeight />} />
              <Route path="/lengthgrith" element={<LengthGrith />} />
              
          </Routes>
        </BrowserRouter>
          </div>
        
      </div>
    
  );
}
}

export default App;