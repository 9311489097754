import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class volumetricWeight extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
      

        <div className="container mt-5 termsp">
        <h2 className="text-center mb-4">
          Understanding Volumetric Weight with Parcel Lodge
        </h2>

        <div className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">What is Volumetric Weight?</h3>
            <p className="card-text">
              Volumetric weight is the calculated weight of a parcel based on
              its length, width, and height, converted into volumetric
              kilograms. Different couriers and services may use varying
              formulas to determine volumetric weight. For instance, express and
              economy services might have distinct volumetric calculations.
            </p>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">Why is Volumetric Weight Charged?</h3>
            <p className="card-text">
              The larger an item, the more space it occupies on a vehicle or
              aircraft, increasing shipping costs. When an item is large but
              lightweight, the shipping price is determined by its size rather
              than its actual weight.
            </p>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">
              How to Calculate Volumetric Weight for Courier Delivery
            </h3>
            <p className="card-text">
              While different couriers use various metrics, the most common
              formula involves multiplying the three parcel dimensions in
              centimeters and dividing the result by 5,000.
            </p>
            <ol>
              <li>
                <strong>Multiply the Three Dimensions:</strong> Multiply the
                length, width, and height of your parcel in cm. <br />
                <em>Example:</em> 20 cm x 20 cm x 30 cm = 12,000 cm³.
              </li>
              <li>
                <strong>Divide by 5,000:</strong> Divide the result by 5,000 to
                get the volumetric weight in kilograms. <br />
                <em>Example:</em> 12,000 / 5,000 = 2.4 kg.
              </li>
              <li>
                <strong>Compare to the Physical Weight:</strong> If the
                volumetric weight exceeds the physical weight, your shipment
                will be billed based on the volumetric weight.
              </li>
            </ol>
            <p>
              <strong>Note:</strong> If you’re using inches, divide by 305
              instead.
            </p>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">
              How to Calculate Volumetric Weight for Freight Delivery
            </h3>
            <p className="card-text">
              For road freight, air freight services, and airlines, a divisor of
              6,000 is typically used.
            </p>
            <ul>
              <li>
                Multiply the length, width, and height of your parcel in cm.
              </li>
              <li>Divide the result by 6,000.</li>
            </ul>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">
              Calculate Total Chargeable Weight of a Shipment
            </h3>
            <p className="card-text">
              Different couriers use different methods to determine the total
              chargeable weight of a shipment, which can significantly affect
              the overall cost.
            </p>
            <p className="card-text">
              At <strong>Parcel Lodge</strong>, we ensure transparency in our
              calculations and provide tools to help you easily determine your
              parcel's chargeable weight, whether it's based on physical or
              volumetric measurements.
            </p>
            <p className="text-center font-weight-bold">
              Ship with confidence using Parcel Lodge, knowing that all
              dimensions and weights are accounted for accurately, and costs are
              clearly outlined.
            </p>
          </div>
        </div>
      </div>




                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default volumetricWeight;
        