import React, { Component } from 'react';
import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import dummyimg from './assets/images/dummyimg.jpg';
import Header from './Header';
import Footer from './Footer';
import CarrierCard from './assets/CarrierCard';
import axios from 'axios';

class CourierList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shippingMethods: [],
      filteredMethods: [],
      weight: localStorage.getItem('weight') || 1,
      value: localStorage.getItem('value') || 1,
      carriers: [],
      loading: true,
      error: null,
      showShops: false, // New state to control whether to show shops or couriers
      shopsData: [], // Stores shops data when service points are shown
      servicePoints: [],
      carriersStatus: false
    };
  }

  componentDidMount() {
    this.fetchShippingMethods();
  }

  setShowShops = (showShops, servicePoints = []) => {
    this.setState({ showShops, servicePoints, loading: false });
  };

  fetchShippingMethods() {
    const { weight, value } = this.state;
    const fromPostalCode = localStorage.getItem('collectionpostcodes');
    const parcelCount = localStorage.getItem('parcelcount');
    const toCountry = localStorage.getItem("selectedcountry");
    const insurance = localStorage.getItem("insurance") ?? "false";
    localStorage.setItem("insurance", insurance);
    const url = `https://www.parcellodge.com/api/parcels/retrieve-shipping_methods?from_postal_code=${fromPostalCode}&to_country=${toCountry}&weight=${weight}&parcelValue=${value}&parcelCount=${parcelCount}&insurance=${insurance}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        
          this.setState({
            carriers: data.data,
            loading: false,
            carriersStatus: data.status
          });
console.log("carriers---",this.state.carriers)
      })
      .catch(error => {
        this.setState({
          error,
          loading: false
        });
      });
  }

  handleShopNowClick = async (shipment, country, id) => {
    this.setState({ loading: true, showShops: true });
    console.log("shipment===",shipment);
    console.log("country===",country);
    console.log("id===",id);
    localStorage.setItem('selectedShippingId', id);
    try {
      const response = await axios.get('https://www.parcellodge.com/api/parcels/retrieve-service-points', {
        params: {
          from_postal_code: localStorage.getItem("collectionpostcodes"), // replace with actual data
          to_country: country.name, // dynamically fetched from country
          carrier: shipment.carrier,  // dynamically fetched from shipment
        },
      });
      this.setState({ servicePoints: response.data.data });
    } catch (error) {
      this.setState({ error });
    } finally {
      this.setState({ loading: false });
    }
  };

  handleBookNowClick = async (shipment, shipmentprice, id) => {
    // console.log("book shipment data=====",shipment.carrier);
    // console.log("book shipment===",shipmentprice);
    // console.log("bppk id===",id);
    // localStorage.setItem('selectedShippingId', id);
    // localStorage.setItem("quoteestimation", shipmentprice);
    // localStorage.setItem("carrierselected", shipment.carrier);
    
  };

  render() {
    const { carriers, loading, error, showShops, servicePoints } = this.state;
    // Show loading state
    if (loading) {
      return <div>Loading...</div>;
    }

    // Show error message if any error occurs
    if (error) {
      return <div>Error: {error.message}</div>;
    }

    return (
      <div className="page-container">
        <Header />
        <section className="ftco-about">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-md-12 wrap-about">
                <div className="container mt-10">
                  <section className="shipping-methods">
                    <div className="container px-4 px-lg-5 mt-5">
                      <div className="row d-flex justify-content-center align-items-center h-100">
                        <div className="col-12">
                          <div className="d-flex justify-content-between align-items-center mb-2">
                            <h2>Compare Prices</h2>
                          </div>
                        </div>
                      </div>

                      {/* Conditionally render carrier cards or shop data */}
                      {console.log("loging carrier====",carriers.length)}
                      {!showShops ? (
                        carriers.length === 0 && !this.state.carriersStatus ? (
                          // Display message if no carriers are available or status is false
                          <div className="no-carriers-message">
                            <span>{carriers?.message || "No carriers support the given details."}</span>
                          </div>
                        ) : carriers.length > 0 && this.state.carriersStatus ? (
                          // Render carriers if available and status is true
                          <div className="grid test12">
                            {console.log("check carriers===", carriers)}
                            {carriers.map((carrier, index) => (
                              <CarrierCard 
                                key={index} 
                                carrier={carrier} 
                                setShowShops={this.setShowShops}
                              />
                            ))}
                          </div>
                        ) : (
                          // Show message if status is false
                          <div className="no-carriers-message">
                            <span>{carriers.message || "No carriers support the given details."}</span>
                          </div>
                        )
                      ) : (
                        // Render the shop data when showShops is true
                        <div className="shop-data">
                          <h3>Available Shops/Service Points</h3>
                          <div className="row">
                          {servicePoints.map((point) => (
  <div key={point.id} className="col-md-4 mb-3">
    <div className="card">
      <div className="card-body">
        <h5 className="card-title">{point.name}</h5>
        <p className="card-text">
          <strong>Address:</strong> {point.street} {point.house_number}, {point.postal_code}, {point.city}
          <br />
          <strong>Carrier:</strong> {point.carrier}
          <br />
          <strong>Country:</strong> {point.country}
          <br />
          <strong>Distance:</strong> {point.distance} meters
          <br />
          <strong>Opening Times:</strong>
          <ul>
            {Object.entries(point.formatted_opening_times).map(([day, times]) => (
              <li key={day}>{`Day ${parseInt(day) + 1}: ${times.join(', ')}`}</li>
            ))}
          </ul>
        </p>
        <a
          href={`/bookparcel?shippingid=${point.id}`}
          className="btn btn-primary w-100"
          onClick={() => {
            localStorage.setItem('selectedServicePoint', JSON.stringify(point)); // Save point data to localStorage
          }}
        >
          Book Now
        </a>
      </div>
    </div>
  </div>
))}

                          </div>


                        </div>
                      )}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
  
}

export default CourierList;
