import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class lengthGrith extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
    

      <div className="container mt-5 termsp">
        <h2 className="text-center mb-4">
          How to Calculate the Girth and Length of a Package Using Parcel Dimensions
        </h2>

        <div className="card mb-4">
          <div className="card-body">
            <p className="card-text">
              Many UK Parcel Delivery Services and International Couriers enforce restrictions 
              on the maximum combined length and girth of packages. This combined measurement 
              is commonly referred to as the <strong>'Max Girth.'</strong>
            </p>
            <p className="card-text">
              These limitations ensure that your parcel fits securely on sorting conveyor belts 
              and within delivery vehicles. At <strong>Parcel Lodge</strong>, we specialize in 
              delivering large and heavy parcels. No matter what you're shipping, our flexible 
              courier services leverage the world’s top carriers to accommodate any oversized item requirements.
            </p>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">
              How to Calculate the Length & Girth of Your Parcel Manually
            </h3>
            <p className="card-text">To calculate the girth of your package, follow these steps:</p>
            <ol>
              <li>
                <strong>Measure the three parcel dimensions in centimeters.</strong><br />
                <em>Example:</em> 50 cm (Length) x 40 cm (Width) x 30 cm (Height).
              </li>
              <li>
                <strong>Add the two smallest dimensions together and multiply by two.</strong> 
                This gives you the girth of your parcel.<br />
                <em>Example:</em> 40 cm (Width) + 30 cm (Height) = 70 cm. 70 cm x 2 = 140 cm (Girth).
              </li>
              <li>
                <strong>Add the longest dimension to the girth to get the combined length and girth.</strong><br />
                <em>Example:</em> 50 cm (Length) + 140 cm (Girth) = 190 cm (Combined Length and Girth).
              </li>
            </ol>
          </div>
        </div>

        <div className="card mb-4">
          <div className="card-body">
            <h3 className="card-title">
              Why is Parcel Girth Measurement Important?
            </h3>
            <p className="card-text">
              At <strong>Parcel Lodge</strong>, we partner with leading couriers like 
              <strong>UPS, DHL, FedEx, TNT, and DPD</strong>. Each of our partner couriers has unique restrictions on 
              the maximum length and girth they will accept. For example:
            </p>
            <ul>
              <li><strong>UPS</strong> accepts packages with a maximum combined length and girth of 419 cm.</li>
              <li><strong>TNT</strong> allows for larger dimensions.</li>
              <li><strong>DPD</strong> has smaller size limitations.</li>
            </ul>
            <p className="card-text">
              When you use the <strong>Parcel Lodge Instant Quote Tool</strong>, we only display delivery options 
              that fit the dimensions of your parcel. This is why it’s essential to provide accurate size and weight details.
            </p>
            <p className="card-text">
              Keep in mind that some couriers may apply surcharges if your package exceeds their standard restrictions. 
              For instance, <strong>UPS</strong> charges an additional fee of around <strong>£30</strong> for parcels exceeding 
              <strong>330 cm</strong> in combined length and girth. <strong>Parcel Lodge</strong> ensures all such fees are included 
              in your quote, so there are no unexpected costs.
            </p>
            <p className="text-center font-weight-bold">
              With <strong>Parcel Lodge</strong>, you can ship confidently, knowing your package will meet courier requirements 
              and your costs will be transparent and upfront.
            </p>
          </div>
        </div>
      </div>




                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default lengthGrith;
        