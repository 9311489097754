import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class twentyFourHours extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container mt-10 my-5 termsp">
                        <h1>24-Hour Delivery by ParcelLodge</h1>
            <p>When urgency meets reliability, ParcelLodge’s 24-hour delivery service is here to meet your needs. Whether it’s important documents, packages, or essential goods, we ensure your consignment is collected and delivered promptly, without compromising on quality.</p>
            
            <h2>Why Choose Our 24-Hour Delivery Service?</h2>
            
            <h3>Speed You Can Count On</h3>
            <p>Our 24-hour courier service guarantees collection of your goods between 9 AM and 5 PM on your specified day (Monday to Friday), with delivery made the very next working day. Perfect for those who need a quick yet reliable solution for their shipping needs.</p>
            
            <h3>Nationwide Coverage</h3>
            <p>With an expansive network across the UK, ParcelLodge is equipped to collect your items from almost any location. No matter where your goods are headed, we’ve got it covered.</p>
            
            <h3>Seamless Handling and Delivery</h3>
            <p>We believe in efficiency. That’s why the same driver who collects your shipment will deliver it, minimizing handling time and ensuring your package arrives securely and on time.</p>
            
            <h3>Track Every Step</h3>
            <p>Our advanced tracking systems allow you to monitor your shipment’s journey in real-time. Plus, we provide proof of delivery as standard, offering complete transparency and peace of mind.</p>
            
            <h3>Fully Insured Freight</h3>
            <p>Your items are insured up to £25,000, ensuring your consignment is safeguarded. Need additional coverage? We offer customizable insurance options to suit your requirements.</p>
            
            <h3>Easy Online Booking</h3>
            <p>Booking has never been simpler. Use our user-friendly online platform to get an instant quote, select your collection day, and finalize your booking within minutes.</p>
            
            <h3>No Shipment Too Small</h3>
            <p>From single parcels to larger consignments, our 24-hour service handles it all with the utmost care. Whatever you need to send, we’ll ensure it’s delivered with precision and professionalism.</p>
            
            <p className="text-center mt-4"><strong>Experience next-day delivery made effortless with ParcelLodge’s 24-hour courier service. Trust us to get your goods where they need to be, on time, every time.</strong></p>

    </div>



                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default twentyFourHours;
        