import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class fortyEightHours extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container my-5 termsp">
            <h1 className="text-center mb-4">Affordable and Reliable 48-Hour Delivery with ParcelLodge</h1>
            <p>Looking for a dependable courier service without the rush of same-day or next-day delivery? ParcelLodge’s 48-hour delivery service combines cost-effectiveness with the same reliability and care that’s synonymous with our brand.</p>
            
            <h2>Features of Our 48-Hour Delivery Service</h2>
            
            <h3>Flexible Collection and Delivery</h3>
            <p>We’ll collect your goods between 9 AM and 5 PM on your specified day (Monday to Friday) or the next working day. Delivery will be completed within two working days of collection, ensuring a perfect balance of speed and efficiency.</p>
            
            <h3>Nationwide Network</h3>
            <p>Our extensive depot network spans the UK, allowing us to collect and deliver from virtually any location. Whether it’s a small village or a bustling city, ParcelLodge ensures your goods are in capable hands.</p>
            
            <h3>Efficient End-to-End Service</h3>
            <p>With the same driver managing both collection and delivery, your shipment’s journey is streamlined for maximum efficiency. This reduces handling time and keeps your goods safe.</p>
            
            <h3>Real-Time Tracking</h3>
            <p>Stay updated every step of the way. Our tracking systems provide real-time updates on your shipment’s status, while proof of delivery ensures you’re always in the know.</p>
            
            <h3>Insurance for Peace of Mind</h3>
            <p>Your freight is automatically insured up to £25,000. Need higher coverage? Our customizable insurance options allow you to tailor protection to your needs, ensuring total peace of mind.</p>
            
            <h3>Effortless Online Booking</h3>
            <p>With our online quotes and booking system, arranging your 48-hour delivery is quick and simple. Get a competitive quote and schedule your collection in just a few clicks.</p>
            
            <h3>Versatility for All Consignments</h3>
            <p>Whether it’s a single parcel or a bulk shipment, ParcelLodge’s 48-hour courier service is designed to handle consignments of all sizes with equal care and attention to detail.</p>
            
            <h2>Why Choose ParcelLodge?</h2>
            <ul>
                <li><strong>Affordable Solutions:</strong> Save money without sacrificing reliability.</li>
                <li><strong>Expert Service:</strong> Our team is dedicated to making your shipping experience seamless and stress-free.</li>
                <li><strong>Customizable Options:</strong> Tailor our services to suit your specific delivery needs.</li>
            </ul>
            
            <p className="text-center mt-4"><strong>ParcelLodge’s 48-hour delivery service is the ideal choice for non-urgent shipments that still demand attention to detail. Let us handle your goods with the care and professionalism they deserve, at a price that works for you.</strong></p>
        </div>



                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default fortyEightHours;
        