import React, { Component } from 'react';
    import './assets/animate.css';
import './assets/open-iconic-bootstrap.min.css';
import './assets/animate.css';
import './assets/owl.carousel.min.css';
import './assets/owl.theme.default.min.css';
import './assets/magnific-popup.css';
import './assets/aos.css';
import './assets/ionicons.min.css';
import './assets/bootstrap-datepicker.css';
import './assets/jquery.timepicker.css';
import './assets/flaticon.css';
import './assets/icomoon.css';
import './assets/style.css';
import Header from './Header';
import Footer from './Footer';

class multiDrop extends Component {
constructor(props) {
super(props);
this.state = {};
}
render() {
    return (
        <div className="page-container">
            <Header />
            <section className="ftco-about">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-12 wrap-about">
                            
                        <div className="container my-5 termsp">
            <h1 className="text-center mb-4">Multi-Drop Delivery by ParcelLodge</h1>
            <p>For businesses that need to send multiple packages to different locations in one efficient trip, ParcelLodge’s multi-drop delivery service is the ultimate solution. Whether you’re delivering products to customers, suppliers, or retail outlets, our tailored service ensures every package reaches its destination seamlessly.</p>
            
            <h2>Why Choose Our Multi-Drop Delivery Service?</h2>
            
            <h3>Efficient Route Optimization</h3>
            <p>Our team uses advanced logistics planning and cutting-edge routing technology to create the most efficient delivery paths. This not only saves time but also reduces costs, ensuring your multi-drop deliveries are handled with maximum efficiency.</p>
            
            <h3>Nationwide Coverage</h3>
            <p>No matter how many stops you need, ParcelLodge’s extensive UK-wide network ensures we can collect and deliver from multiple locations across the country. From urban centers to remote areas, your parcels are in expert hands.</p>
            
            <h3>Dedicated Driver Service</h3>
            <p>With ParcelLodge, your goods are managed by a dedicated driver who oversees the entire delivery process. This minimizes handling, reduces the risk of errors, and ensures every parcel arrives exactly where it’s meant to be.</p>
            
            <h3>Real-Time Tracking and Proof of Delivery</h3>
            <p>Keep tabs on your shipments with our real-time tracking system. You’ll receive updates at every step of the journey, and proof of delivery is provided for each stop, offering full transparency and accountability.</p>
            
            <h3>Scalable Solutions</h3>
            <p>Whether you’re sending five packages or fifty, our multi-drop delivery service is fully scalable to meet your business’s growing needs. From small local deliveries to larger regional distributions, we’ve got you covered.</p>
            
            <h3>Cost-Effective and Time-Saving</h3>
            <p>By consolidating multiple deliveries into one trip, you save on transportation costs and time. This streamlined approach is perfect for businesses looking to optimize their operations and improve their bottom line.</p>
            
            <h3>Comprehensive Insurance Coverage</h3>
            <p>For added peace of mind, all shipments handled by ParcelLodge are insured up to £25,000 as standard. Additional insurance coverage is available to meet the specific requirements of your business.</p>
            
            <h3>User-Friendly Online Booking</h3>
            <p>Our online booking system makes arranging multi-drop deliveries simple and stress-free. Get a quote, schedule your delivery, and track progress all from the comfort of your home or office.</p>
            
            <h2>Added Features for Multi-Drop Delivery</h2>
            <ul>
                <li><strong>Specialized Services:</strong> ParcelLodge’s multi-drop delivery service is designed to give you the reliability and professionalism of a dedicated courier service while accommodating multiple stops.</li>
                <li><strong>Advanced Locator Systems:</strong> Our vehicles are equipped with state-of-the-art locator systems to ensure precise navigation and reduce time spent finding each delivery address.</li>
                <li><strong>Efficient Collection and Delivery:</strong> The same driver collects and delivers your consignments, minimizing handling and transit time for maximum efficiency.</li>
                <li><strong>No Consignment Too Small:</strong> From small parcels to larger shipments, ParcelLodge handles every delivery with the utmost care and attention.</li>
            </ul>
            
            <h2>Who Can Benefit from Multi-Drop Delivery?</h2>
            <ul>
                <li><strong>Retailers:</strong> Deliver products to multiple stores or customers efficiently.</li>
                <li><strong>Wholesalers:</strong> Distribute goods to various outlets or distributors in one trip.</li>
                <li><strong>E-Commerce Businesses:</strong> Simplify deliveries for large volumes of customer orders.</li>
                <li><strong>Event Planners:</strong> Transport equipment and supplies to multiple venues seamlessly.</li>
            </ul>
            
            <h2>Why ParcelLodge?</h2>
            <p>At ParcelLodge, we understand the importance of timely, reliable deliveries for your business. Our multi-drop delivery service combines efficiency, flexibility, and affordability to help you meet your operational goals without compromise.</p>
            
            <p className="text-center mt-4"><strong>Let ParcelLodge take the stress out of multi-location deliveries. Trust us to handle your shipments with care, precision, and the professionalism you deserve. Ready to streamline your logistics? Book your multi-drop delivery today.</strong></p>
        </div>



                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    );
}
}
        
        export default multiDrop;
        